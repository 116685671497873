body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scrollbar-gutter: stable; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html::-webkit-scrollbar {
  width: 6px;
}

html::-webkit-scrollbar-track {
  background: #000;
  padding: 0;
  /* border-radius: 10px; */
}

html::-webkit-scrollbar-thumb {
  background: rgb(39, 39, 39);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:hover {
  background: rgb(79, 78, 78);
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb:active {
  background: rgb(79, 78, 78);
  border-radius: 10px;
}