.card-spotlight {
  position: relative;
  background-color: #000;
  padding: 2rem;
  overflow: hidden;
  --mouse-x: 10%;
  --mouse-y: 10%;
  --spotlight-color:rgba(255, 255, 255, 0.25);
}

.card-spotlight::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at var(--mouse-x) var(--mouse-y), var(--spotlight-color), transparent 20%);
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.card-spotlight:hover::before,
.card-spotlight:focus-within::before {
  opacity: 0.6;
}
